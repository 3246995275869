<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <h2 class="h2 card-header ">{{ $t('Register') }}</h2>
          <div class="card-body">
            <form>
              <div class="row justify-content-center">
                <base-input class="form-group col-md-6" v-model.trim="formData.firstName" type="text"
                            :placeholder="$t('First Name')" :error="errors.firstNameError"></base-input>
              </div>
              <div class="row justify-content-center">
                <base-input class="form-group col-md-6" v-model.trim="formData.lastName" type="text"
                            :placeholder="$t('Last Name')" :error="errors.lastNameError"></base-input>
              </div>
              <div class="row justify-content-center">
                <custom-form-component class="col-md-6" ref="customForm"></custom-form-component>
              </div>
              <div class="row justify-content-center">
                <base-input class="form-group col-md-6" v-model.trim="formData.email" type="text"
                            autocomplete="username"
                            placeholder="Email" :error="errors.emailError"></base-input>
              </div>
              <div class="row justify-content-center">
                <base-input class="form-group col-md-6" v-model.trim="formData.password" type="password"
                            isPassword="true" :placeholder="$t('Password')"
                            autocomplete="new-password"
                            :error="errors.passwordError"></base-input>
              </div>
              <div class="row justify-content-center">
                <base-input class="form-group col-md-6" v-model.trim="formData.passwordConfirmation"
                            type="password" isPassword="true" :placeholder="$t('Confirm Password')"
                            autocomplete="current-password"
                            :error="errors.passwordConfirmationError"
                            @enter="registration"
                ></base-input>
              </div>
              <div class="row justify-content-center my-3">
                <terms-checkbox
                    name="terms"
                    @checkbox:change="onCheckboxUpdate"
                    class="col-md-6 text-left"
                    :error="errors.terms"
                />
              </div>
              <div class="row justify-content-center">
                <news-checkbox
                    name="news"
                    @checkbox:change="onCheckboxUpdate" class="col-md-6 text-left"/>
              </div>
            </form>
            <div class="row justify-content-center">
              <loader v-if="loading"/>
              <captured-submit v-else :action="$options.AUTH_TYPE.REGISTRATION">
                <template v-slot:default="{execute}">
                  <button-component
                      class="btn registration-btn m-4"
                      @btn-click="execute(registration)"
                      @disabled-click="checkTerms"
                      :disabled="!formData.terms"
                  >
                    {{ $t('Register') }}
                  </button-component>
                </template>
              </captured-submit>
            </div>
            <social-auth :type="this.$options.AUTH_TYPE.REGISTRATION" :terms="formData.terms"/>
            <div class="button-colors">
              <p> {{ $t('If you have already registered, please') }} </p>
              <router-link class="link" to="/login">{{ $t('click here to login') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BaseInput from "../components/base-components/BaseInput";
import {mapActions} from "vuex";
import {AUTH_TYPE, ERROR} from "../const";
import CustomFormComponent from "../components/CustomFormComponent"
import Loader from "../components/Loader";
import SocialAuth from "../components/base-components/SocialAuth";
import TermsCheckbox from "../components/base-components/checkboxes/TermsCheckbox";
import NewsCheckbox from "../components/base-components/checkboxes/NewsCheckbox";
import ButtonComponent from "../components/base-components/buttons/ButtonComponent";
import CapturedSubmit from "../components/base-components/buttons/CapturedSubmit";
import {validateEmail} from "@/utils/helpers";

export default {
  name: "RegistrationScreen",
  components: {
    CapturedSubmit,
    ButtonComponent, NewsCheckbox, TermsCheckbox, SocialAuth, Loader, CustomFormComponent, BaseInput
  },
  AUTH_TYPE,
  data() {
    return {
      loading: false,
      additionalParamsToErrormessage: null,
      formData: {
        email: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
        news: false,
        terms: false
      },
      errors: {
        emailError: '',
        passwordError: '',
        passwordConfirmationError: '',
        firstNameError: '',
        lastNameError: '',
        terms: '',
      },
    }
  },
  methods: {
    ...mapActions(['DO_REGISTRATION']),
    redirectAfterRegistration() {
      this.$router.push({name: 'home'});
    },
    checkTerms(){
      if (this.formData.terms) return true;
      this.errors.terms = 'Please accept terms & conditions'
    },
    registration({token}={}) {
      if (this.loading) return;

      this.clearErrors();
      if (!this.formData.email || this.formData.password.length < 8 || this.formData.passwordConfirmation.length < 8
          || !this.formData.firstName.length || !this.formData.lastName.length) {
        this.loading = false;
        !this.formData.email ? this.errors.emailError = 'Enter your Email' : null;
        !this.formData.firstName.length ? this.errors.firstNameError = 'Enter your first name' : null;
        !this.formData.lastName.length ? this.errors.lastNameError = 'Enter your last name' : null;
        this.formData.password.length < 8 ? this.errors.passwordError = 'The password is too short' : null;
        this.formData.passwordConfirmation.length < 8 ? this.errors.passwordConfirmationError = 'The password is too short' : null;
        return;
      } else if (this.formData.password != this.formData.passwordConfirmation) {
        this.loading = false;
        this.errors.passwordError = 'PASSWORDS DON’T MATCH';
        this.errors.passwordConfirmationError = 'PASSWORDS DON’T MATCH';
        return;
      }
      if (!validateEmail(this.formData.email)) {
        this.errors.emailError = ERROR.WRONG_EMAIL;
        return;
      }
      if (!this.validateCustomInputs()) return;

      this.loading = true;
      let data = {
        email: this.formData.email,
        password: this.formData.password,
        password_confirmation: this.formData.passwordConfirmation,
        last_name: this.formData.lastName,
        first_name: this.formData.firstName,
        news: this.formData.news,
        terms: this.formData.terms,
        token
      }
      Object.assign(data, this.$refs.customForm.getFieldsData())

      this.DO_REGISTRATION(data)
          .then(() => {
            this.loading = false;
            this.clearErrors();
            this.redirectAfterRegistration();
          })
          .catch((data) => {
            this.loading = false;

            if (data && data.error && data.message) {
              this.errors.passwordError = data.message
            }
          })
    },
    clearErrors() {
      this.errors.emailError = '';
      this.errors.passwordError = '';
      this.errors.passwordConfirmationError = '';
      this.errors.firstNameError = '';
      this.errors.lastNameError = '';
    },
    validateCustomInputs() {
      return this.$refs.customForm.validate()
    },
    onCheckboxUpdate({name, value}) {
      this.formData[name] = value;
      this.errors[name] = ''
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.container {
  margin: 150px auto;
}

.link {
  color: $link-accent;
}
.registration-btn{
  background-color: var(--settings-registration-button-background);
  color: var(--settings-registration-button-text-color);
  position: relative;
  z-index: 1;
  border-radius: .3rem;
  &:before{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: var(--settings-registration-button-background);
    transition: all .2s linear;
    border-radius: .3rem;
  }
  &:hover{
    background-color: var(--settings-registration-button-background);
    color: var(--settings-registration-button-text-color);
    box-shadow: none;
    &:before{
      filter: blur(3px);
      opacity: .5;
      transform: translate(3px, 3px);
    }
  }
}
.entertainment {
  body {
    background-color: black !important;
  }

  .container {
    margin: 70px auto 0;
  }

  .card-header {
    color: #F5F5F5;
  }

  .card {
    margin-top: 10rem;
    background-color: #080809;

    .button-colors {
      color: #d0d0d0;
    }
  }

  .card-header {
    background-color: #060607;
  }

  .link {
    color: #FFFFFF;
    border-color: #1d2124;
  }
}
</style>
