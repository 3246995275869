<template>
	<div>
		<slot
			:execute="executeCaptcha"
		/>
	</div>
</template>

<script>
  import {loadScript} from "@/utils/helpers";
  import {mapState} from "vuex";

  const CAPTCHA_SCRIPT_LINK = `https://www.google.com/recaptcha/api.js`

  export default {
    name: "CapturedSubmit",
    props: {
      action: {
        type: String,
        default: 'login'
      }
    },
    computed:{
      ...mapState({
        settings: state => state.channel.settings,
      }),
      CAPTCHA_ID(){
        return this.settings?.recaptcha?.['recaptcha-site-key']
      },
      CAPTCHA_SCRIPT(){
        return `${CAPTCHA_SCRIPT_LINK}?render=${this.CAPTCHA_ID}`
      }
    },
    methods:{
      onSubmit(token){
        this.input = token
      },
      executeCaptcha(resolve, reject = resolve){
        if (window.grecaptcha){
          window.grecaptcha?.ready(
              () => {
                try{
                  window.grecaptcha.execute(this.CAPTCHA_ID, {
                    action: this.action
                  }).then(token => resolve?.({token: token}))
                } catch (err) {
                  reject?.({token: null})
                }
              }
          )
        } else {
          resolve?.();
        }

      },
      loadCaptchaScript(){
        !window.grecaptcha && this.CAPTCHA_ID && loadScript(this.CAPTCHA_SCRIPT)
      }
    },
    watch:{
      CAPTCHA_SCRIPT(){
        this.loadCaptchaScript()
      }
    },
    created() {
      this.loadCaptchaScript()
    }
  }
</script>

<style scoped>

</style>